body {
  transition: background-color 0.3s, color 0.3s;
  height: 100%;
  margin: 0;
}

html {
  height: 100%;
  margin: 0;
}

.app-container {
  max-width: 1200px;
  margin: auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-header {
  text-align: center;
  margin-bottom: 20px;
}

.app-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
}

.error-message {
  color: red;
  margin: 10px 0;
}

.dark-mode {
  background-color: #181818;
  color: #f0f4f8;
}

.theme-toggle {
  cursor: pointer;
}

.card-selection-section {
  width: 100%;
  max-width: 600px;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  transition: background-color 0.3s, color 0.3s;
}

.card-selection-section.dark-mode {
  background-color: #2a2a2a;
  border: 1px solid #444;
}

.card-selection-section label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

.card-selection-section input,
.card-selection-section select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  font-size: 16px;
  transition: border 0.3s ease;
}

.card-selection-section input:focus,
.card-selection-section select:focus {
  border-color: #007bff;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.card-selection-section input.dark-mode,
.card-selection-section select.dark-mode {
  background-color: #333;
  color: #f0f4f8;
  border: 1px solid #555;
}

.card-selection-section input:focus.dark-mode,
.card-selection-section select:focus.dark-mode {
  border-color: #007bff;
}

.social-share-section {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 15px;
  margin-top: 20px;
  background-color: #f9f9f9;
  text-align: center;
  transition: background-color 0.3s, color 0.3s;
}

.social-share-section.dark-mode {
  background-color: #2a2a2a;
  border: 1px solid #444;
}

.social-share-section p {
  margin-bottom: 10px;
  font-size: 16px;
}

.social-share-buttons {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: center;
}

.social-share-buttons .btn {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.social-share-buttons .btn span {
  display: none;
}

.social-share-buttons .btn-primary {
  background-color: #007bff;
  color: white;
}

.social-share-buttons .btn-info {
  background-color: #17a2b8;
  color: white;
}

.social-share-buttons .btn-danger {
  background-color: #dc3545;
  color: white;
}

.social-share-buttons .btn-success {
  background-color: #28a745;
  color: white;
}

.submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  transition: background-color 0.3s, color 0.3s;
  max-width: 200px;
  margin: auto;
}

.submit-btn:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.response-section {
  padding: 20px; /* Same padding as .app-container */
  background-color: white;
  border-radius: 8px;
  transition: background-color 0.3s, color 0.3s;
}

.response-section.dark-mode {
  background-color: #2a2a2a;
  color: #f0f4f8;
}

@media (min-width: 576px) {
  .social-share-buttons .btn span {
    display: inline;
  }
}

.card-form-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 15px;
}

.card-form-grid label {
  grid-column: span 2;
}

@media (max-width: 1200px) {
  .app-container {
    padding: 15px;
  }
}

@media (max-width: 992px) {
  .app-container {
    padding: 10px;
  }

  .card-selection-section {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .app-container {
    padding: 10px;
  }

  .card-selection-section {
    padding: 10px;
  }

  .social-share-buttons {
    justify-content: center;
  }

  .card-form-grid {
    grid-template-columns: 1fr;
  }

  .card-form-grid .submit-btn {
    max-width: 100%;
    justify-self: center;
  }
}

@media (max-width: 576px) {
  .app-container {
    padding: 5px;
  }

  .card-selection-section {
    padding: 5px;
  }

  .social-share-buttons {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
  }

  .card-form-grid {
    grid-template-columns: 1fr;
  }

  .card-form-grid .submit-btn {
    max-width: 100%;
    justify-self: center;
  }
}

/* Remove unnecessary custom styles */
.sidebar {
  position: fixed;
  top: 0;
  left: -250px;
  width: 250px;
  height: 100%;
  background-color: #333;
  color: #fff;
  transition: left 0.3s ease;
  z-index: 1000;
}

.sidebar.open {
  left: 0;
}

.sidebar .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  background: none;
  border: none;
  color: #fff;
}

.sidebar .sidebar-content {
  padding: 20px;
}

.sidebar .sidebar-content h3 {
  margin-top: 0;
}

.sidebar .sidebar-content ul {
  padding-left: 0;
}

.sidebar .sidebar-content ul li {
  margin-bottom: 10px;
}

.menu-toggle {
  position: absolute;
  left: 10px;
  top: 10px;
  background: none;
  border: none;
  color: #333;
}

.menu-toggle:focus {
  outline: none;
}

.offcanvas {
  background-color: #f8f9fa;
}

.offcanvas .nav-link {
  padding: 10px 15px;
  color: #495057;
  font-weight: 500;
}

.offcanvas .nav-link:hover {
  background-color: #e9ecef;
  color: #212529;
}

.offcanvas .nav-link.active {
  background-color: #e9ecef;
  color: #212529;
}

.offcanvas .form-check-label {
  font-weight: 500;
}

.app-footer {
  background-color: #f8f9fa;
  padding: 1rem;
  text-align: center;
}


